// ----------------------------------------------------------------------

/* import { HashLink } from 'react-router-hash-link';
import useLocales from '../hooks/useLocales';
import { PATHS } from '../routes/paths'; */

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const sk = {
  default: {
    search: 'Vyhľadať',
    showResults: 'Zobraziť výsledky',
    showDetail: 'Zobraziť detail',
    showFilter: 'Zobraziť filter',
    hideFilter: 'Skryť filter',
    cultureObjects: 'Katalóg objektov',
    countSheets: 'Sčítacie hárky',
    multimedia: 'Videogaléria',
    articles: 'Články',
    articles_blog: 'Články',
    articles_newsfeed: 'Články',
    map: 'Mapa',
    aboutSlovakiana: 'O Slovakiane',
    funny: 'Edu materiály',
    notFound: 'Nenájdené',
    noResultsFound: 'Nenašli sa žiadne výsledky',
    moreResults: 'Hľadaj ďalšie výsledky. Počet: ',
    home: 'Domov',
    Subcategory: 'Podkategória',
    favorites: 'Obľúbené',
    moreInfo: 'Viac info',
    loadMore: 'Zobraziť viac',
    order: 'Zoradenie',
    chooseCat: 'Typ obľúbenej položky',
    bactToList: 'Späť na zoznam',
    next: 'Nasledujúci',
    prev: 'Predchádzajúci',
    mentionId: 'Tento objekt je spomínaný v článku',
    license: 'Licencia',
    cultureObjectDetail: 'Kulturny objekt',
    multimediaDetail: 'Multimediálny objekt',
    countingSheetsDetail: 'Sčítací hárok',
    articlesDetail: 'Článok',
    articlesDetail_blog: 'Článok',
    articlesDetail_news: 'Článok',
    confirm: 'Potvrdiť',
    decline: 'Zamietnuť',
    cancel: 'Zrušiť akciu',
    share: 'Zdieľať',
    print: 'Vytlačiť',
    download: 'Stiahnuť',
    favorite: 'Pridať do obľúbených',
    institutionDetial: 'Inštitúcie',
    companies: 'Zoznam inštitúcií',
    foundForYou: 'Našli sme ',
    clearAll: 'Vymazať všetky filtre',
    copyLink: 'Embedovací odkaz',
    copyIframe: 'Kopírovať',
    copyIframeSuccess: 'Iframe bol úspešne skopírovaný',
    learnMore: 'Zistiť viac',
    showMore: 'Zobraziť viac',
    showLess: 'Zobraziť menej',
    noCategory: 'Bez kategórie',
    goUp: 'Späť na začiatok',
    noResults: 'Ľutujeme, pre tvoje vyhľadávanie sme nenašli žiaden výsledok',
    noText: 'Ľutujeme, tento text je prázdny.',
    page: 'Strana',
    of: 'z',
    authorRights: 'Autorské práva',
    pageNumber: 'Strana',
    outOfBounds: 'Mimo rozsah!',
    samsungAlert1: 'Váš prehliadač (Samsung Internet) nemusí zobrazovať túto webovú stránku správne.',
    samsungAlert2: 'Zvážte namiesto toho použitie prehliadača, ktorý je v súlade so štandardmi.',
    samsungAlert3: 'Odporúčame Firefox, Microsoft Edge alebo Google Chrome.',
  },
  carousel: {
    art: 'Výtvarné umenie',
    text: 'Textové dokumenty',
    history: 'Historická zbierka',
    arch: 'Architektúra a pamiatky',
    video: 'Audiovizuálny obsah',
    cult: 'Tradičná ľudová kultúra',
    nature: 'Prírodné dedičstvo',
  },
  footer: {
    stayInContact: 'Zostaňme v kontakte',
    aboutSlovakiana: 'O Slovakiane',
    aboutSlovakianaText:
      'Slovakiana vytvára prostredie, v ktorom môže každý spoznávať svoju kultúru a históriu jednoduchým spôsobom. Zachovávame kultúrne dedičstvo pre budúce generácie.',
    contact: 'Kontakt',
    help: 'Pomoc',
    companies: 'Zoznam správcovských inštitúcií',
    accesibility: 'Vyhlásenie o prístupnosti',
    conditions: 'Všeobecné podmienky',
    authorRights: 'Autorské práva',
    newsletter1: 'Prihlás sa na odber noviniek',
    nwesletter2: '',
    Popupnewsletter1: 'Zostaňme v kontakte!',
    Popupnewsletter2: 'Dostávaj novinky a príbehy o kultúrnom dedičstve mesačne do svojej e-mailovej schránky.',
    writeEmail: 'Napíš tvoj e-mail',
    login: 'Prihlásiť',
    confirm1: 'Odoslaním súhlasíš so spracovaním ',
    confirm2: 'osobných údajov.',
  },
  homepage: {
    title: `Objav viac ako `,
    titlePart2: ` digitalizovaných objektov kultúrneho dedičstva na jednom mieste`,
    subtitle: 'Nevieš, ako začať objavovať kultúrne dedičstvo?',
    rerollButt: 'Začni náhodnou kolekciou',
    searchPlaceholder: 'Hľadaj podľa názvu diela, autora, lokality alebo zadaj ľubovoľné slovo',
    searchPlaceholderMobile: 'Poďme spolu objavovať',
    chooseCat: 'Žáner videozáznamu',
    news: 'Aktuálne oznamy',
    articles: 'Objav príbehy o slovenskom kultúrnom dedičstve',
    showMoreArticles: 'Zobraziť všetky články',
    aboutSl: 'O Slovakiane',
    aboutSlSubtitle:
      'Prostredie, ktoré poskytuje prístup k digitalizovaným kultúrnym objektom, pamiatkam<br>a tradičnej ľudovej kultúre. Zachováva kultúrne dedičstvo Slovenska<br>pre budúce generácie.',
    cultureObjectsText:
      'Prostredie, kde nájdeš digitalizované objekty<br>z múzeí, galérií, knižníc a ďalších<br>správcovských inštitúcií.',
    countSheetsText:
      'Prostredie, kde nájdeš digitalizované hárky<br>zo sčítania obyvateľstva Slovenska<br>v rokoch 1930, 1939 a 1940.',
    multimediaText:
      'Spoznaj kultúrne dedičstvo zvukom a obrazom!<br>Sleduj naše reportáže, inštruktážne videá<br>a náučné animácie.',
    articlesText:
      'Príležitosť dozvedieť sa viac o pamiatkach,<br>umení či zvykoch a tradíciách nájdeš<br>v populárnonáučných článkoch.',
    mapText: 'Objav miesta, ktoré majú čo ponúknuť<br>a navštív pamiatky a správcovské inštitúcie<br>prstom na mape.',
    funnyText:
      'Edukatívne materiály pre malých aj veľkých,<br>ktorí sa chcú zabaviť<br>a popritom sa aj niečo nové naučiť.',
    explore: 'Preskúmať pamiatku',
    mapTitle: 'Odporúčame navštíviť',
    exploreOnMap: 'Zobraziť mapu pamiatok a inštitúcií',
    mostReaded: 'Najčítanejšie články na Slovakiane',
  },
  sheet: {
    title: 'Spoznaj svojich predkov',
    subtitle: `v digitalizovaných hárkoch zo sčítania obyvateľstva Slovenska v rokoch 1930, 1939 a 1940.`,
    searchText: 'Hľadaj podľa názvu okresu, obce alebo ulice',
    noBlacked: 'Len odčiernené',
    countSheets: ' výsledkov',
    district: 'Okres',
    searchDistrict: 'Hľadaj okres',
    town: 'Obec',
    searchTown: 'Hľadaj obec',
    street: 'Ulica',
    year: 'Rok sčítania',
    searchYear: 'Hľadaj rok sčítania',
    tooltip: `Sčítacie hárky z rokov 1939 a 1940 sú dostupné len v začiernenej verzii, pretože ešte neuplynula 90-ročná doba ochrany osobných údajov, ktoré sa na nich nachádzajú.`,
    chooseFirst: 'Vyber najskôr okres',
  },
  objects: {
    loading: 'Načítavam...',
    downloading: 'Sťahujem...',
    title: 'Katalóg slovenského kultúrneho dedičstva',
    chooseCat: 'Kategória',
    authorTag: 'Autor',
    materialTag: 'Materiál',
    techniqueTag: 'Technika',
    periodTag: 'Datovanie',
    institutionTag: 'Správcovská inštitúcia',
    formTag: 'Formát',
    locationTag: 'Lokalita',
    searchCat: 'Hľadaj kategóriu',
    searchAuthorTag: 'Hľadaj autora',
    searchMaterialTag: 'Hľadaj materiál',
    searchTechniqueTag: 'Hľadaj techniku',
    searchPeriodTag: 'Hľadaj datovanie',
    searchInstitutionTag: 'Hľadaj inštitúciu',
    searchFormTag: 'Hľadaj formát',
    searchLocationTag: 'Hľadaj lokalitu',
    subtitle:
      'kde nájdeš digitalizované kultúrne objekty z múzeí, galérií, knižníc a ďalších správcovských inštitúcií.',
    info: 'Tematické kolekcie objektov pripravované tímom Slovakiany',
    searchText: 'Hľadaj podľa názvu diela, autora, lokality alebo zadaj ľubovoľné slovo ',
    withPicture: 'Len s obrázkom',
    forDownload: 'Len na stiahnutie',
    countObjects: ' výsledkov',
    similaryObjects: 'Podobné objekty',
    distance: 'Rádius (vzdialenosť): ',
    filterMap: 'Hľadať lokalitu',
    clearMap: 'Zrušiť lokalitu',
    author: 'Hľadať autora',
    date: 'Hľadať datovanie',
    material: 'Hľadať materiál',
    technic: 'Hľadať techniku',
    institution: 'Hľadať správcovskú inštitúciu',
    medium: 'Hľadať formát',
    category: 'Hľadať kategórie',
    subcategory: 'Podkategória',
    dateFrom: 'Od roku',
    dateTo: 'Do roku',

    showMore: 'Zobraziť všetko',
    suggested: 'Odporúčané kolekcie',
    show: 'Zobraziť',
    hide: 'Skryť',
    giveCredit: 'Uvedieš zdroj www.slovakiana.sk',
    doNotCommerical: 'Nepoužiješ objekt na komerčné účely',
    doNotDerivate: 'Nezasiahneš do objektu a nevytvoríš jeho rozmnoženinu',
    license: 'licencia',
    licenseBY:
      'Objekt môžeš rozširovať, upravovať a používať aj na komerčné účely s povinnosťou uvedením zdroja www.slovakiana.sk.',
    licenceCC: 'Ľutujeme, tento digitálny objekt nemôžeme sprístupniť kvôli autorskoprávnej ochrane.',
    licenseBYusage: ' ťa oprávňuje použiť objekt za podmienok: ',
    licenseBYND:
      'Objekt môžeš rozširovať, upravovať a používať len na nekomerčné účely s povinnosťou uvedením zdroja www.slovakiana.sk.',
    licenseBYSA: 'Objekt nemôžeš rozširovať, upravovať a používať ani na nekomerčné účely.',
    licenseMore: 'Viac o licencii ',
    metadata: {
      Labelling: 'Značenie',
      Title: 'Názov',
      Costumes: 'Kostýmy',
      'Musical director': 'Hudobný režisér',
      Curator: 'Kurátor',
      Date: 'Datovanie',
      Other: 'Iná rola',
      Publisher: 'Autorita ktorá publikovala KO',
      Screenwriter: 'Scenár',
      Ilustrator: 'Ilustrátor',
      Photographer: 'Fotograf',
      Translator: 'Prekladateľ',
      Keeper: 'Vlastník',
      Actor: 'Výkonný umelec',
      'Book designer': 'Grafická úprava',
      Composer: 'Hudba',
      Actors: 'Účinkujúci',
      'Issuer note': 'Vydavateľské údaje',
      Genre: 'Žáner',
      Style: 'Sloh',
      'General use': 'Využitie',
      Series: 'Seriál/cyklus',
      Producer: 'Producent',
      ISBN: 'ISBN',
      'Place of origin': 'Lokalita pôvodu',
      'Date of finding': 'Dátum nálezu',
      Country: 'Krajina',
      County: 'Okres',
      Municipality: 'Obec',
      'Number of census sheet': 'Číslo hárku',
      'Box number': 'Číslo škatule',
      Street: 'Ulica',
      'Source language': 'Jazyk originálu',
      Material: 'Materiál',
      'Technique used': 'Technika',
      Last: 'Čas trvania',
      Format: 'Formát',
      'Physical description': 'Fyzický popis',
      Labeling: 'Značenie',
      Description: 'Popis',
      'Author of assignment': 'Autor popisu',
      'Date of Declaration NKP': 'Dátum vyhlásenia NKP',
      'Date of recording': 'Dátum zaznamenania',
      Keywords: 'Kľúčové slová',
      Originality: 'Stupeň pôvodnosti',
      Owner: 'Správcovská inštitúcia',
      'Asset number': 'Inventárne číslo',
      Identifier: 'Identifikátor',
      License: 'Licencia',
      'The exercise of rights': 'Výkon práv',
      URL: 'URL',
      "Object's rights": 'Právny stav',
      Category: 'Kategória',
      Subcategory: 'Podkategória',
      'Fund department': 'Fondové oddelenie',
      Editing: 'Strih',
      Language: 'Jazyk',
      'Post-production supervisor': 'Vedúci výroby',
      author: 'Autor',
      date: 'Datovanie',
      Source: 'Zdroj',
      Writer: 'Námet',
      Director: 'Réžia',
      Dramaturge: 'Dramaturgia',
      'Sound editor': 'Zvuk',
      Subtitle: 'Podnázov',
      Performer: 'Umelec',
      created: 'Vytvorené',
      Name: 'Názov',
      updated: 'Aktualizované',
      Categories: 'Kategórie',
      tags: 'Tagy',
      'Other title': 'Iný názov',
      mentionedInArticles: 'Spomenuté v článkoch',
      objectUse: 'Použitie objektu',
      originDateNote: 'Poznámka k dátovaniu',
      'Title translation': 'Preklad názvu',
      'Edition, nr': 'Edícia, č. zv.',
      'Part title': 'Názov časti',
      'Author of dialog': 'Autor dialógov',
      Periodicity: 'Periodicita',
      'Edition,nr': 'Edícia, č. zv.',
      Author: 'Autor',
      'Director of photography': 'Kamera',
      illustrator: 'Ilustrátor',
      'Dircetor of photography': 'Kamera',
      'Main director': 'Hlavný režisér',
      ISSN: 'ISSN',
      'Box Number': 'Číslo škatule',
      'Author of assigment': 'Autor popisu',
      main: 'Hlavný názov',
      exact: 'Exaktný názov',
      alter: 'Alternatívny názov',
      subtitle: 'Podnázov',
      other: 'Iný názov',
      original: 'Pôvodný názov',
      resp_statement: 'Kolektív autorov',
      translator: 'Prekladateľ',
      owner: 'Vlastník',
      actor: 'Výkonný umelec',
      mainDirector: 'Hlavný režisér',
      performer: 'Umelec',
      diaglogues: 'Autor dialogov',
      producent: 'Výrobca',
      dop: 'Kamera',
      composer: 'Hudba',
      curator: 'Kurátor',
      Length: 'Dĺžka',
      costumes: 'Kostýmy',
      photographer: 'Fotograf',
      pps: 'Vedúci výroby',
      'Co-authors': 'Spoluautori',
      'Alternate title': 'Alternatívny názov',
      Locations: 'Lokality',
      Annotation: 'Anotácia',
      Type: 'Typ',
      urn_svk_cair_term_type_role_other: 'Ďalšie úlohy',
      Institution: 'Správcovská inštitúcia',
      Document: 'Dokument',
      Number: 'Číslo',
      'Update date': 'Dátum aktualizácie',
    },
  },
  multimedia: {
    title: 'Spoznaj kultúrne dedičstvo zvukom a obrazom',
    subtitle: 'sleduj naše reportáže, inštruktážne videá a náučné animácie.',
    searchText: 'Hľadaj podľa ľubovoľného pojmu',
    forDownload: 'Len na stiahnutie',
    countMultimedia: ' výsledkov',
    chooseCat: 'Vyber kategóriu',
  },
  companies: {
    title: `Zoznam múzeí, galérií a ďalších správcovských inštitúcií,`,
    subtitle: 'ktorých kultúrne objekty nájdeš na portáli Slovakiana.',
    searchText: 'Hľadaj podľa názvu inštitúcie',
    countComp: ' výsledkov',
    area: 'Lokalita',
    type: 'Typ spoločnosti',
    chooseCat: 'Typ správcovskej inštitúcie',
    searchCat: 'Hľadaj typ inštitúcie',
    chooseLoc: 'Lokalita',
    searchLoc: 'Hľadaj lokalitu',
  },
  favorites: {
    title: `Tvoje obľúbené objekty a články na jednom mieste.`,
    subtitle: `Vytvor si vlastný katalóg pre rýchlejší
prístup k obľúbeným položkám pri ďalšej
návšteve portálu Slovakiana. `,
    info: 'Bez potreby registrácie zostávajú tvoje obľúbené položky uložené v tomto počítači, až kým si nevymažeš cache pamäť prehliadača.',
    searchText: 'Hľadaj obľúbenú položku',
    countFavStart: 'V zozname máte',
    countFav: ' obľúbených položiek',
    runPresent: 'Spustiť prezentáciu ',
    edit: 'Editovať položky',
    endEdit: 'Koniec editácie',
    clearAll: 'Zmazať všetky',
    emptyList: 'V zozname obľúbených sa nenachádzajú žiadne položky.',
    clearText: 'Ste si istý že chcete vykonať akciu zmazania z obľúbených ?',
    categoryTypes: 'Typy obľúbených položiek',
    category1: 'Sčítacie hárky',
    category2: 'Články a pamiatky',
    category3: 'Kultúrne objekty',
    category4: 'Videogaléria',
    category6: 'EDU materiály',
    category5: 'Správcovské inštitúcie',
  },
  map: {
    // title: 'Objav miesta, ktoré majú čo ponúknuť',
    title: 'Hľadaj podľa lokality, názvu pamiatky alebo inštitúcie',
    subtitle: 'Vydajte sa za pamiatkami, múzeami, galériami a ďalšími kultúrnymi inštitúciami na Slovensku.',
    searchText: 'Hľadaj podľa lokality, názvu pamiatky alebo inštitúcie',
    countTrip: ' výsledkov',
    typeFilter: 'Typ pamiatky a správcovskej inštitúcie',
    withText: 'Len s popisom',
    institution: 'Inštitúcia',
    monument: 'Pamiatka',
    group: 'Súbor inštitúcií a pamiatok',
  },
  articles: {
    title: 'Príležitosť dozvedieť sa viac',
    subtitle: ' o pamiatkach, umení či zvykoch a tradíciách nájdeš v populárno-náučných článkoch.',
    searchText: 'Hľadaj podľa ľubovoľného slova ',
    countArticle: ' výsledkov',
    countArticle1: ' výsledok',
    countArticle234: ' výsledky',
    withText: 'Len s popisom',
    nextQuestion: 'Ďaľšia otázka',
    prevQuestion: 'Predchádzajúca otázka',
    resultQuiz: 'Dokončiť quiz',
    restart: 'Začať znova',
    noAnswer: 'Musíš označiť aspoň jednu možnosť.',
    from: 'z',
    result: 'Výsledok',
    result2: 'Výborne! tvoje vedemosti sú na 1*',
    result3: 'Fíha! to musí byť tvoja parketa.',
    result4: 'Nevadí! nabudúce to bude lepšie.',
    result5: 'Nezúfaj! Prečítaj si článok ešte raz :)',
    similarObjects: 'Objekty súvisiace s článkom',
    similarObjectsInst: 'Objekty správcovskej inštitúcie',
    name: 'Názov',
    address: 'Adresa',
    position: 'Súradnice',
    clickToCopy: '* Kliknutím na značku skopírujete informácie do schránky *',
    selectAuthor: 'Autor článku',
    chooseCat: 'Tematická oblasť',
    searchCat: 'Hľadaj tematickú oblasť',
    chooseCatMap: 'Typ pamiatky a správcovskej inštitúcie',
    searchCatMap: 'Hľadaj Typ',
    category: 'Kategória',
    article: 'Článok',
    monument: 'Pamiatka',
    institution: 'Inštitúcia',
    no_type: 'Neuvedené',
    related: 'Súvisiace články',
    quizTitle: 'Otestuj, ako pozorne čítaš',
    videoTitle: 'Odkaz na video: ',
    author: 'Autor',
    date: 'Datovanie',
  },
  about: {
    title: `Príbeh portálu slovakiana`,
    upperText1: `Slovakiana je prostredie, ktoré poskytuje nadšencom, profesionálom, učiteľom, študentom a bádateľom
    prístup ku slovenskému kultúrnemu dedičstvu a hárkom zo sčítania obyvateľstva Slovenska.
    Sprístupňuje digitalizované objekty vo vysokom rozlíšení, ktoré je možné okrem prezerania i
    zdieľať, sťahovať a ďalej využívať v rôznych odvetviach, v prípade, ak to umožňuje ich autorskoprávna
    ochrana.`,
    upperText2: `Webový portál bol spustený v roku 2015 a v súčasnosti obsahuje takmer 800 000 digitalizovaných
    muzeálnych predmetov, textových a archívnych dokumentov, pamiatok, objektov výtvarného umenia,
    tradičnej ľudovej kultúry, prírodného dedičstva a filmovej tvorby, ktoré pochádzajú z viac ako 150
    slovenských správcovských inštitúcií.`,
    upperText3: `Samotnú digitalizáciu kultúrnych objektov zabezpečuje Slovenská národná galéria, Slovenská národná
    knižnica, Slovenský filmový ústav, Pamiatkový úrad SR, Malokarpatské osvetové stredisko, Štátna
    vedecká knižnica v Prešove a Slovenský ľudový a umelecký kolektív a Národné osvetové centrum, ktoré
    je prevádzkovateľom portálu Slovakiana.`,
    lowerTextTitle: `Čo je cieľom Slovakiany?`,
    lowerText: `Hlavným cieľom portálu je sprístupňovať výsledky digitalizácie kultúrneho dedičstva Slovenska, ktorú zabezpečujú rôzne pamäťové a fondové inštitúcie. Nájdete tu zbierkové predmety z viac ako 70 slovenských inštitúcií (múzeí, galérií, knižníc, osvetových stredísk a iných inštitúcií), ktorých digitalizáciu zabezpečujú: `,
    lowerTextList1: `Slovenská národná galéria`,
    lowerTextList2: `Slovenská národná knižnica`,
    lowerTextList3: `Slovenský filmový ústav`,
    lowerTextList4: `Pamiatkový úrad SR`,
    lowerTextList5: `Národné osvetové centrum`,
    lowerTextList6: `Malokarpatské osvetové stredisko`,
    lowerTextList7: `Štátna vedecká knižnica v Prešove`,
    lowerTextList8: `Slovenský ľudový a umelecký kolektív`,
    sendMessage: `Poslať správu`,
    msgQuestion: `V prípade akýchkoľvek otázok nám neváhaj napísať.`,
    questionTitle: `FAQ`,
    questionTitle2: `VYHĽADÁVANIE NA PORTÁLI SLOVAKIANA`,
    question1: `Čo je to kultúrne dedičstvo Slovenska?`,
    answer1: `<div>Kultúrne dedičstvo je súbor hmotných a nehmotných kultúrnych objektov, tvorivej činnosti človeka a
    vývoja ľudskej spoločnosti od najstarších čias až po súčasnosť, majúc na zreteli ich historickú a
    kultúrno-spoločenskú hodnotu.</div>
    <div><b>Nehmotné dedičstvo</b> prezentujú tradície, folklór, piesne, básne, povesti, hudobné diela, rituály,
    spoločenské zvyklosti, vedomosti a skúsenosti týkajúce sa prírody a vesmíru alebo vedomosti a
    zručnosti potrebné pre tradičné remeslá.</div>
    <div><b>Hmotné dedičstvo</b> prezentujú výtvory ľudskej činnosti a objekty minulosti. Patria sem pamiatky
    a pamiatkové územia, muzeálne a galerijné predmety, archívne dokumenty vrátane filmových a
    zvukových dokumentov, archiválie, písomnosti a ďalšie textové dokumenty.</div>
    `,
    answer12: `tu.`,
    question2: `Čo je kultúrny a digitálny objekt?    `,
    answer2: `Pod pojmom kultúrny objekt rozumieme zbierkový predmet nachádzajúci sa v múzeu, galérii, knižnici a
    inej správcovskej inštitúcii (napr. obraz, muzeálny predmet, kniha, film, ale i národná kultúrna
    pamiatka). Návštevníci portálu Slovakiana majú možnosť prezerať si digitálne reprezentácie týchto
    kultúrnych objektov, a teda digitálne objekty v rôznych formátoch (fotografia, panoráma, video, audio,
    3D).`,
    question3: `Čo je správcovská inštitúcia?`,
    answer3: `Správcovská inštitúcia je pamäťová a fondová inštitúcia, ktorá spravuje a uchováva kultúrne objekty vo
    svojom zbierkovom fonde (múzeá, galérie, knižnice a iné), spravuje a ochraňuje pamiatkový fond alebo
    dokumentuje nehmotné kultúrne dedičstvo a tradičnú ľudovú kultúru.
    Správcovské inštitúcie rozhodujú, ktoré kultúrne objekty sú digitalizované a následne zverejnené na
    portáli Slovakiana. Viac o jednotlivých inštitúciách sa dozviete v `,
    answer31: 'Zoznam správcovských inštitúcií.',
    question4: `Prečo na portáli Slovakiana nemôžem vidieť všetky digitalizované kultúrne objekty (digitálne
      objekty)?`,
    answer4: `<div>To, ako je nastavená právna ochrana kultúrnych objektov určuje správcovská inštitúcia, ktorá uchováva
    objekty vo svojom zbierkovom fonde (múzeá, galérie, knižnice, atď.). Portál Slovakiana neurčuje, ktoré
    kultúrne objekty sú sprístupnené verejnosti.</div>
    <div>Na portáli Slovakiana možno sprístupniť:</div>
    <ul>
    <li>kultúrne objekty, ktoré nie sú autorskoprávne chránené;</li>
    <li>kultúrne objekty, pre ktoré neexistuje iná zákonná prekážka ich sprístupnenia (napr. zobrazenie
    osobných údajov);</li>
    <li>kultúrne objekty, ktoré je možné sprístupniť na základe zákonnej výnimky/špecifického režimu;</li>
    <li>kultúrne objekty, pri ktorých je možnosť ich sprístupnenia na základe uzatvorenej licenčnej zmluvy
    s vlastníkom/ správcom kultúrneho objektu;</li>
    <li>kultúrne objekty, pri ktorých už uplynula lehota ochrany.</li>
    </ul>
    <div>V rámci portálu Slovakiana je sprístupnené veľké množstvo kultúrnych objektov od obrazov,
    historických predmetov, kníh až po digitálne zobrazenie veľkých nehnuteľných objektov, akými sú hrady
    a rôzne pamiatky. Možnosť sprístupnenia kultúrnych objektov závisí od posúdenia stavu právnej
    ochrany, ktorá sa na kultúrny objekt vzťahuje. Aj vzhľadom na typológiu kultúrnych objektov ide
    najčastejšie o ochranu autorskoprávnu. Nemožno zanedbať ani vplyv a prepojenie s ochranou
    osobných údajov, utajovaných skutočností, prípadne samotný zámer ochrany napríklad
    archeologického náleziska formou nezobrazenia niektorých metadátových údajov. Preto aj v prípade, ak
    nejde o autorskoprávne chránený kultúrny objekt, môže nastať situácia, že takýto objekt nemôže byť
    sprístupnený vôbec alebo iba v určitom rozsahu.</div>
    <div>Primárnym právnym predpisom v oblasti autorského práva je zákon č. 185/2015 Z. z. Autorský zákon,
    ktorý upravuje jednak predpoklady vzniku autorských diel, možnosti ich dovoleného použitia, dĺžku
    lehoty ochrany, ale aj mnohé iné súvisiace témy.
    Ku každému autorskému dielu sa viaže autorské právo. Autorské právo zahŕňa výhradné osobnostné
    práva (právo označiť/neoznačiť svoje dielo menom alebo pseudonymom, rozhodnúť o zverejnení svojho
    diela, právo na nedotknuteľnosť diela) a výhradné majetkové práva (použiť dielo a udeľovať súhlas na
    každé použitie diela). Osobnostné práva sú neprevoditeľné a smrťou autora zanikajú, majetkové práva
    trvajú počas života autora a 70 rokov po jeho smrti. Počas tejto doby trvania majetkových práv je dielo
    chránené, použiť dielo je možné len so súhlasom autora alebo iného nositeľa práva. Autorský zákon č.
    618/2003 z. z. o autorskom práve a právach súvisiacich s autorským právom (autorský zákon) v § 56
    demonštratívne uvádza, čoho sa môže domáhať autor v prípade neoprávneného zásahu alebo hrozby
    takéhoto zásahu do jeho práva.</div>
    `,
    question5: `Prečo sú niektoré sčítacie hárky začiernené?`,
    answer5: `<div>Z legislatívnych dôvodov nie je možné na portáli Slovakiana sprístupniť objekty, ktoré
    spadajú pod autorskoprávnu ochranu alebo obsahujú osobné či citlivé údaje obyvateľov
    Slovenskej Republiky. V prípade začierňovania ide o súvis s ochranou osobných údajov, kde
    sme vzhľadom na zákonné povinnosti boli nútení niektoré časti dočasne začierniť. Týka sa to
    hárkov zo sčítania obyvateľstva v rokoch 1939 a 1940. Po uplynutí 90 rokov od sčítania
    obyvateľstva bude možné tieto hárky sprístupniť verejnosti.</div>
    <div>Z uvedeného dôvodu nie je Národné osvetové centrum, ako prevádzkovateľ portálu
    Slovakiana, oprávnené poskytovať prístup k nezačierneným sčítacím hárkom, ani inak o nich
    poskytovať informácie.</div>
    <div>Začiernené sčítacie hárky na portáli Slovakiana slúžia verejnosti primárne ako pomôcka pri
    bádaní, vďaka ktorej je umožnená rýchla identifikácia sčítacieho hárku v Slovenskom
    národnom archíve, a to na základe jedinečného identifikačného čísla, ktoré sa nachádza v
    názve sčítacieho hárku sprístupnenom na portáli Slovakiana.</div>
    <div>V prípade záujmu o nezačiernenú papierovú alebo digitálnu kópiu sčítacieho hárku je
    potrebné podať žiadosť na korešpondenčnú adresu Slovenského národného archívu. Z
    dôvodu ochrany sčítacích hárkov z rokov 1939 a 1940 sa tieto nepredkladajú na štúdium, ale
    vyhotovujú sa len ich kópie za úhradu nákladov.</div><br>
    Formulár žiadosti<br>
    Emailový kontakt: <a href="mailto:archiv.sna@minv.sk">archiv.sna@minv.sk</a><br>
    Adresa na podávanie žiadostí: Ministerstvo vnútra Slovenskej Republiky, Slovenský národný
    archív, Drotárska cesta 42, P.O.Box 115, 840 05 Bratislava 45`,
    question6: `Prečo neviem nájsť niektoré z kultúrnych objektov, ktoré poznám z múzea/galérie?`,
    answer6: `Digitalizácia kultúrneho dedičstva je stále prebiehajúci proces, a teda nie všetky kultúrne objekty už boli
    digitalizované. Zároveň, výber objektov, ktoré sú sprístupnené na portáli Slovakiana je v kompetencii
    samotných správcovských inštitúcií, rovnako, ako aj určenie ich autorskoprávnej ochrany`,
    question7: `Môžem aj ja prispieť na Slovakianu?`,
    answer7: `Na portáli Slovakiana sprístupňujeme len kultúrne objekty pochádzajúce zo správcovských
    inštitúcií. Ak by ste však chceli zdieľať poznatky o niektorom z kultúrnych objektov, národnej
    kultúrnej pamiatke či lokalite, môžete nám svoje príspevky (max. 1,5 normostrany) zaslať na
    <a href="mailto:slovakiana@nocka.sk">kontaktný email</a> a po posúdení ho zverejníme na portáli v časti Články.`,
    question8: `Našli ste doma starožitnosť, prípadne objekt podobný tým, ktoré vidíte na
    Slovakiane? Chcete o ňom vedieť viac a čo sním? Na koho sa obrátiť?`,
    answer8: `Často nás kontaktujete s prosbou o poskytnutie komplexnejších informácii k starožitnosti,
    ktorú ste našli, prípadne kúpili a vidíte obdobné predmety aj na portáli. Avšak, tím Slovakiana
    nepracuje s konkrétnymi kultúrnymi objektami, ale zabezpečuje ich online prezentáciu z
    výsledkov digitalizácie správcovských inštitúcií.
    Ak máte otázku týkajúcu sa kultúrnych objektov, môžete kontaktovať priamo správcovskú
    inštitúciu (uvedenú v popise objektu). Kontaktné informácie jednotlivých inštitúcií nájdete
    v `,
  },
  edu: {
    title: 'Edukatívne materiály pre malých aj veľkých,',
    subtitle: 'ktorí sa chcú zabaviť a popritom sa aj niečo nové naučiť.',
    searchText: 'Hľadaj edukačný materiál ',
    countObjects2: ' výsledkov',
    download: 'Stiahnúť',
    preview: 'Náhľad',
    chooseCat: 'Typ edukačného materiálu',
  },
  preview: {
    download: 'Tento digitálny objekt je možné stiahnúť.',
    noPreview: 'Tento digitálny objekt nemá náhľad.',
    license:
      'Kultúrny objekt je zdigitalizovaný. Z dôvodu právnej ochrany nie je možné jeho zobrazenie prostredníctvom portálu Slovakiana. Pre získanie podrobnejších informácií o kultúrnom objekte môžete osloviť inštitúciu, ktorá objekt odborne spravuje.',
    nodigitalized: 'Kultúrny objekt nie je zdigitalizovaný.',
  },
  sort: {
    sheetName: 'Číslo škatule',
    name: 'Názov',
    date: 'Dátum',
    popularity: 'Popularita',
    relevance: 'Relevancia',
    update: 'Aktualizácia',
    municipality: 'Názov obce',
  },
  acessibility: {
    title: 'VYHLÁSENIE O PRÍSTUPNOSTI OBSAHU',
    text1: `V rámci portálu Slovakiana je sprístupnené veľké množstvo rôznych kultúrnych objektov od obrazov, historických predmetov, kníh až po digitálne zobrazenie veľkých nehnuteľných objektov, akými sú hrady a rôzne námestia. Možnosť sprístupnenia kultúrnych objektov závisí od <b>posúdenia stavu právnej ochrany,</b> ktorá sa na kultúrny objekt vzťahuje. Aj vzhľadom na typológiu kultúrnych objektov ide najčastejšie o ochranu autorskoprávnu. Nemožno zanedbať ani vplyv a prepojenie s ochranou osobných údajov, utajovaných skutočností, prípadne samotný zámer ochrany napríklad archeologického náleziska formou nezobrazenia niektorých metadátových údajov. Preto aj v prípade, ak nejde o autorskoprávne chránený kultúrny objekt, môže nastať situácia, že takýto objekt nemôže byť sprístupnený vôbec alebo iba v určitom rozsahu.`,
    text2: `Primárnym právnym predpisom v oblasti <b>autorského práva</b> je zákon č. 185/2015 Z. z. Autorský zákon, ktorý upravuje jednak predpoklady vzniku autorských diel, možnosti ich dovoleného použitia, dĺžku lehoty ochrany, ale aj mnohé iné súvisiace témy. Na portáli Slovakiana možno sprístupniť verejnosti len tie diela, ktorých lehota trvania autorských práv už uplynula, teda dielo sa stalo „voľným“, alebo diela, pri ktorých bola uzatvorená licenčná zmluva umožňujúca tento spôsob použitia.`,
    text3: `Istú výnimku predstavuje inštitút tzv. <b>osirelých diel.</b> Osirelé dielo je slovesné dielo v písomnej forme, akým je najmä kniha, časopis, noviny, hudobné dielo vyjadrené v písomnej forme, prípadne dielo audiovizuálne, kde autora nemožno určiť vôbec, resp. ak bol určený, nie je možné ho nájsť ani napriek dôslednému vyhľadávaniu. Ďalšou podmienkou je, že dielo musí byť uložené v knižnici, archíve, múzeu, škole alebo u zákonného depozitára. Pri splnení všetkých zákonných povinností možno takéto dielo sprístupniť prostredníctvom portálu Slovakiana, ale iba do času, kým autor status osirelého diela neukončí.`,
    text4: `Špecifickou kategóriou sú <b>diela obchodne nedostupné.</b> V takomto prípade ide, vychádzajúc z aktuálneho autorského zákona, o vydané slovesné dielo v písomnej forme, akým je najmä kniha, časopis alebo noviny, ktorého rozmnoženinu nie je možné bežným spôsobom zakúpiť (uvedené sa nevzťahuje na kúpu použitej veci) a je uložené v knižnici, archíve alebo múzeu. Dielo musí byť navyše zapísané vo verejne prístupnom zozname obchodne nedostupných diel, vedenom Slovenskou národnou knižnicou. Možnosť sprístupnenia tejto kategórie diel je však podmienená uzatvorením <b>rozšírenej hromadnej licenčnej zmluvy.</b>`,
    text5: `Krátkou sumarizáciu možno na portáli sprístupniť tieto kultúrne objekty:
    <br/>
    – kultúrne objekty, ktoré nie sú autorskoprávne chránené a nejestvuje iná zákonná prekážka ich sprístupnenia;
    <br/>
    – v prípade autorskoprávnych kultúrnych objektov:
    <br/>
    <ul>
      <li>ide o kultúrne objekty, pri ktorých už uplynula lehota ochrany;</li>
      <li>ide o kultúrne objekty, ktoré je možné sprístupniť na základe zákonnej výnimky/špecifického režimu;</li>
      <li>ide o kultúrne objekty, pri ktorých je možnosť ich sprístupnenia založená na zmluvnom základe.</li>
    </ul>`,
  },
  rights: {
    title: 'CENTRUM PRE AUTORSKÉ PRÁVA',
    text1:
      '<b>Centrum pre autorské práva</b> (CPAP) predstavuje odborné zázemie pre riešenie právno-technických úskalí digitalizačného procesu so zámerom identifikácie právnej ochrany jednotlivých kultúrnych objektov s možnosťou ich ďalšieho použitia, primárne sprístupnením prostredníctvom vytvoreného centrálneho portálu kultúrneho dedičstva Slovenska - Slovakiana. CPAP je inštitúcia, ktorá vznikla ako výstup projektu <b>Centrálnej aplikačnej infraštruktúry a registratúry</b> a svojou činnosťou významným spôsobom napomáha dosiahnutiu ochrany autorského práva a práv súvisiacich s autorským právom pri digitalizácii a sprístupňovaní digitalizovaného obsahu verejnosti. Vo svojej pracovnej činnosti zabezpečuje viaceré odborné úlohy jednak metodického a jednak praktického charakteru, smerujúce k riešeniu konkrétnych licenčných vzťahov.',
    text2: `Predovšetkým ide o tieto aktivity:
    <ul>
      <li>tvorba a aktualizácia metodiky pre kategorizáciu kultúrnych objektov z pohľadu autorských práv,</li>
      <li>tvorba a aktualizácia metodiky pre kategorizáciu metadát ku kultúrnym objektom,</li>
      <li>negociácie s autormi smerujúce k získaniu súhlasu autora na sprístupnenie diela,</li>
      <li>poradenská činnosť,</li>
      <li>kooperačná činnosť,</li>
      <li>činnosť administratívnej a odbornej podpory,</li>
      <li>právne poradenstvo pamäťovým a fondovým inštitúciám (PFI) v oblasti autorského práva,</li>
      <li>návrhy legislatívnych zmien.</li>
    </ul>`,
  },
  conditions: {
    title: 'Všeobecné podmienky používania ',
    title2: 'Práva súvisiace so spracúvaním osobných údajov',
    text1: `Čl. I<br/>Úvodné ustanovenia`,
    text2: `<ol>
    <li>Predmetom týchto Všeobecných podmienok používania portálu Slovakiana (ďalej aj ako „VPP“) je definovanie podmienok používania, povinností a práv prevádzkovateľa a používateľov služieb portálu Slovakiana.</li>
    <li>Portál kultúrneho dedičstva Slovakiana (ďalej aj ako „portál“ alebo „portál Slovakiana“) je kultúrny portál, ktorý slúži na prezentáciu slovenského kultúrneho dedičstva širokej verejnosti primárne prostredníctvom webového sídla – <a href='https://www.slovakiana.sk' target='_blank'>www.slovakiana.sk</a>.</li>
    <li>Prevádzkovateľom portálu Slovakiana je Národné osvetové centrum, štátna príspevková organizácia, Nám. SNP č. 12, 812 34 Bratislava 1, IČO 00164615 (ďalej ako „prevádzkovateľ“).</li>
  </ol>`,
    text3: `Čl. II<br/>
    Sprístupnenie kultúrneho dedičstva`,
    text4: `<ol>
    <li>Portál Slovakiana je primárne zameraný na sprístupňovanie digitálnej formy kultúrnych objektov verejnosti. Kultúrnym objektom sú na účely týchto VPP všetky kultúrne objekty v správe správcovských inštitúcií, tzn. pamäťových a fondových inštitúcií Slovenskej republiky (ďalej ako „PFI“), ktoré sú poskytovateľmi kultúrnych objektov sprístupňovaných prostredníctvom portálu Slovakiana.</li>
    <li>Kultúrne objekty sprístupňované na portáli Slovakiana môžu byť chránené autorskými právami autorov kultúrnych objektov, právami PFI, prevádzkovateľa alebo inými právami tretích strán.</li>
    <li>Popisné metadáta, ktoré sú súčasťou portálu Slovakiana sú vypracované zamestnancami, prípadne inými odbornými osobami príslušnej PFI a vyjadrujú súčasný stav poznania kultúrneho objektu.</li>
    <li>Obsah sprístupnený na portáli Slovakiana je z autorskoprávneho hľadiska:
      <ol type="a">
        <li><b>autorskoprávne voľn</b> - t.j. voľne prístupný a akékoľvek jeho ďalšie použitie nie je používateľom obmedzené, s výnimkou dodržiavania osobnostných práv autorských diel a práv vzťahujúcich sa na ochranu osobnosti. Autorskoprávne voľné objekty sú prístupné všetkým používateľom,</li>
        <li><b>autorskoprávne chránený </b> – kultúrny objekt chránený autorským právom, ktorého ďalšie použitie závisí od licenčných podmienok, ktoré sa ku kultúrnemu objektu chránenému autorským právom viažu. Rozsah sprístupnenia autorskoprávne chráneného kultúrneho objektu závisí od ustanovení dostupných licenčných zmlúv.</li>
        <li><b>chránený iným spôsobom </b> – kultúrny objekt chránený inými právnymi normami (napr. práva priemyselného vlastníctva, práva na ochranu osobnosti, iné majetkové práva). Akékoľvek ďalšie použitie závisí od ustanovení licenčných zmlúv alebo iných dokumentov umožňujúcich ďalšie použitie kultúrneho objektu.</li>
       <li><b>mimo autorskoprávnej ochrany </b> - nespĺňajú definíciu autorského diela na základe § 3 zákona č. 185/2015 Z. z., Autorský zákon v znení neskorších predpisov, ani nepatria medzi práva priemyselného vlastníctva. Tieto kultúrne objekty sú prístupné všetkým používateľom, niektoré informácie môžu byť však začiernené alebo nedostupné vzhľadom na ochranu osobných údajov, či iných osobnostných práv.</li>
        </ol>
    </li>
    Za správnosť metadát a nastavenie licenčných podmienok sprístupnených kultúrnych objektov zodpovedá príslušná PFI, ktorá tieto kultúrne objekty poskytla.
    </ol> `,
    text5: `Čl. III<br/>
    Práva a povinnosti používateľa`,
    text6: `<ol>
    <li>Používaním portálu Slovakiana používateľ prehlasuje, že sa oboznámil s VPP portálu Slovakiana, ich obsahu rozumie, súhlasí s nimi a zaväzuje sa ich dodržiavať.</li>
    <li>Používateľ je oprávnený prehľadávať informácie sprístupnené na portáli Slovakiana, vyhľadávať medzi kultúrnymi objektmi a metadátami PFI sprístupnenými na portáli Slovakiana a využívať dostupnú funkcionalitu podľa aktuálneho nastavenia portálu.</li>
    <li>Používateľ využíva obsah na vlastné riziko a je povinný riadiť sa pravidlami jeho použitia vzhľadom na súvisiaci stav právnej ochrany.</li>
    <li>Používateľ si je vedomý, že portál Slovakiana môže byť nedostupný počas bežnej údržby alebo po dobu nevyhnutnú na odstránenie prípadných technických problémov.</li>
    <li>Používateľ je oprávnený používať služby portálu Slovakiana výlučne v súlade s platnými právnymi predpismi Slovenskej republiky, týmito VPP, pokynmi prevádzkovateľa, bezpečnostnými pravidlami siete internet a dobrými mravmi.</li>
    <li>Používateľ nie je oprávnený zasahovať do prevádzky a funkčnosti služieb portálu Slovakiana, informácie pozmeňovať alebo inak zneužívať, rovnako nie je oprávnený pokúšať sa získať neautorizovaný prístup k jeho službám.</li>
    <li>Ak Používateľ má pochybnosti o aktuálnosti informácie, je oprávnený upozorniť na to Prevádzkovateľa, ktorý v súvislosti s podnetom môže informovať správcu objektu (PFI), ktorý je zodpovedný za zverejnené informácie o kultúrnych objektoch na portáli Slovakiana, a požiadať ju o nápravu.
    </ol`,
    text7: `Čl. IV<br/>
    Práva prevádzkovateľa`,
    text8: `<ol>
    <li>Prevádzkovateľ si vyhradzuje právo kedykoľvek jednostranne zmeniť znenie týchto VPP portálu Slovakiana.</li>
    <li>Prevádzkovateľ si vyhradzuje právo kedykoľvek zmeniť spôsob prevádzky a služieb portálu Slovakiana a takisto prevádzku prerušiť alebo pozastaviť.</li>
    <li>Prevádzkovateľ si vyhradzuje právo odmietnuť/alebo obmedziť používateľovi prístup na portál Slovakiana, pokiaľ tento používateľ poruší podmienky uvedené vo VPP.</li>
    <li>Akékoľvek informácie poskytnuté prevádzkovateľovi prostredníctvom portálu Slovakiana sa považujú za vlastníctvo prevádzkovateľa, ktorý je oprávnený voľne ich využívať, pokiaľ sa na ne nevzťahujú autorské práva alebo iné pravidlá vyplývajúce zo všeobecne záväzných právnych predpisov.</li>
    <li>Všetky autorské a iné práva súvisiace s funkčnosťou, službami, databázami, vizuálnym stvárnením a informačným obsahom služieb patria prevádzkovateľovi, prípadne tretím stranám, s ktorými má prevádzkovateľ za týmto účelom uzatvorené príslušné zmluvy alebo dohody.</li>
    </ol>
    `,
    text9: `Čl. V<br/>
    Obmedzenie zodpovednosti za škodu`,
    text10: `<ol>
    <li>Prevádzkovateľ nezodpovedá za priame ani nepriame škody, ktoré by používateľovi mohli vzniknúť v súvislosti s využívaním obsahu poskytovaného na portáli Slovakiana.</li>
    <li>Prevádzkovateľ nezodpovedá za akúkoľvek priamu, nepriamu alebo následnú škodu, vrátane ušlého zisku, strát alebo výdavkov vzniknutých v súvislosti s portálom Slovakiana, jeho používaním, alebo nemožnosťou jeho použitia, prípadne s jeho poruchou, chybou, prerušením prevádzky, napadnutím systému a neautorizovaným pozmenením obsahu.</li>
    <li>Prevádzkovateľ nenesie nijakú zodpovednosť za akékoľvek náklady používateľov spojené so zobrazovaním a využívaním portálu Slovakiana.</li>
    <li>Hypertextové odkazy uvádzané pri kultúrnych objektoch slúžia na doplnenie informácií pre používateľov. Prevádzkovateľ nenesie žiadnu zodpovednosť za obsah publikovaný na externých stránkach prepojených  prostredníctvom hypertextových odkazov. Prevádzkovateľ tieto odkazy uvádza v dobrej viere, že ich poskytnutie je v súlade s dobrými mravmi a všeobecne záväznými právnymi predpismi.</li>
    <li id="dataProtection">Prevádzkovateľ v žiadnom prípade nezodpovedá za škodu spôsobenú zmenou VPP portálu Slovakiana, pozastavením alebo zmenou služby, stratou údajov, ako aj zrušením prístupu registrovanému alebo overenému registrovanému používateľovi zo strany prevádzkovateľa, resp. obmedzením prístupu používateľa zo strany prevádzkovateľa. </li>
    </ol>`,
    text11: `Čl. VI<br/>
    Ochrana osobných údajov`,
    text12: `<ol><li>Používateľ pri návšteve portálu Slovakiana a jeho ďalšom používaní môže prevádzkovateľovi poskytnúť osobné údaje (e-mailovú adresu). Súhlas používateľa nadobúda platnosť okamihom dobrovoľného poskytnutia osobných údajov (e-mailovej adresy) prostredníctvom elektronického formulára umiestneného na portáli Slovakiana (prihlásenie sa na odber noviniek) a trvá až kým ho používateľ neodvolá. Používateľ výslovne súhlasí so:
    <ul><li>spracovaním osobných údajov, za podmienok a dodržania Nariadenia Európskeho parlamentu a Rady (EÚ) 2016/679 o ochrane fyzických osôb pri spracúvaní osobných údajov, ktorým sa zrušuje smernica 95/46/ES (všeobecné nariadenie o ochrane údajov) (ďalej len ako „Nariadenie GDPR“), ako aj zákona č. 18/2018 Z. z. Zákon o ochrane osobných údajov a o zmene a doplnení niektorých zákonov v znení neskorších predpisov,</li>
    <li>použitím osobných údajov v rozsahu nevyhnutnom pre dosiahnutie účelu podľa čl. IX ods. 3 VPP,</li>
    <li>poskytnutím niektorých osobných údajov osobám zmluvne spolupracujúcim s prevádzkovateľom.</li></ul>
    </li>
    <li>Dobrovoľným poskytnutím osobných údajov používateľ prehlasuje, že poskytnuté údaje sú správne, pravdivé a aktuálne. V opačnom prípade zodpovedá za prípadnú škodu, ktorú uvedením nesprávnych, nepravdivých alebo neaktuálnych údajov môže prevádzkovateľovi spôsobiť. Používateľ je povinný nahlásiť prevádzkovateľovi akúkoľvek zmenu svojich osobných údajov.</li>
    <li>Prevádzkovateľ spracúva osobné údaje používateľa za nasledovným účelom:
    <ul><li>umožnenie zasielania marketingových produktov (newsletter) prevádzkovateľa alebo osôb v osobitnom zmluvnom vzťahu s prevádzkovateľom.</li>
    </ul>
    <li>Prevádzkovateľ môže spracúvať niektoré z osobných údajov alebo záznamov osobnej povahy vymedzených v nasledujúcom rozsahu: emailová adresa.</li>
    <li>Súhlas používateľa sa vzťahuje na všetky spracovateľské činnosti vykonávané s osobnými údajmi na ten istý účel.</li>
    <li>Používateľ môže súhlas so spracúvaním osobných údajov kedykoľvek odvolať:
    <ul><li>meno a priezvisko,o	zaslaním e-mailu na e-mailovú adresu <a href='mailto:slovakiana@nocka.sk'>slovakiana@nocka.sk</a></li>
    <li>o	zrušením odberu newslettera </li>
    </ul>
    </li><li>Osobné údaje používateľa môžu byť prostredníctvom kontaktného formulára, resp. objednávkového formulára poskytnuté ďalším osobám, s ktorými má prevádzkovateľ sprostredkovateľský zmluvný vzťah.</li>
    <li>Oprávnenou osobou sa rozumie osoba, ktorá prichádza do styku s osobnými údajmi používateľa v rámci svojho pracovnoprávneho vzťahu alebo na základe poverenia prevádzkovateľa. O oznámenie totožnosti oprávnenej osoby, ktorá získava osobné údaje v mene prevádzkovateľa, môže používateľ požiadať na základe písomnej žiadosti adresovanej prevádzkovateľovi.</li>
    <li>Používateľ má za podmienok stanovených v Nariadení GDPR právo požadovať od prevádzkovateľa prístup k osobným údajom týkajúcich sa používateľa a právo na ich opravu alebo vymazanie alebo obmedzenie spracúvania, alebo právo namietať proti spracúvaniu, ako aj právo na prenosnosť údajov. Bližšie sú práva používateľa súvisiace s ochranou osobných údajov upravené v článku 15 až 23 Nariadenia GDPR.</li>
    <li>Prevádzkovateľ má určenú zodpovednú osobu za ochranu osobných údajov, ktorú môže používateľ  kontaktovať v súvislosti so všetkými otázkami týkajúcimi sa spracúvania jej osobných údajov a uplatňovania svojich práv podľa Nariadenia GDPR. Kontaktné údaje na zodpovednú osobu prevádzkovateľa: e-mail: <a href='mailto:slovakiana@nocka.sk'>slovakiana@nocka.sk</a> alebo písomne: Národné osvetové centrum, Nám. SNP 12, 812 34 Bratislava, označenie obálky: Zodpovedná osoba.</li>
    <li>Práva používateľa môžu byť obmedzené iba ak takéto obmedzenie vyplýva z osobitného zákona alebo jeho uplatnením by bola porušená ochrana používateľa, alebo by boli porušené práva a slobody iných dotknutých osôb.</li>
    <li>Používateľ má na základe písomnej žiadosti právo u prevádzkovateľa namietať voči spracúvaniu jeho osobných údajov na účel odlišný od účelu špecifikovaného v týchto VPP.</li>
    <li>Prevádzkovateľ osobné údaje používateľa nezverejňuje a neuskutočňuje ich prenos do tretích krajín.</li>
    <li>Ak sa používateľ domnieva, že spracúvanie osobných údajov, ktoré sa ho týka je v rozpore s Nariadením GDPR, okamžite informuje o tom zodpovednú osobu alebo priamo prevádzkovateľa. V prípade, ak je zodpovedná osoba, či samotný prevádzkovateľ nečinný, môže používateľ podať sťažnosť na Úrade na ochranu osobných údajov Slovenskej republiky, Hraničná 12, 820 07  Bratislava 27, tel. <a gref='tel:+421 /2/ 3231 3214'>+421 /2/ 3231 3214</a>, e-mail: <a href='mailto:statny.dozor@pdp.gov.sk'>statny.dozor@pdp.gov.sk</a> alebo na inom dozornom orgáne v súlade s článkom 77 Nariadenia GDPR.</li>
    <li>Používateľ môže pri prevádzke portálu Slovakiana využívať súbory cookies, t.j. krátke textové súbory, ktoré internetový prehliadač používateľa ukladá v zariadení, pomocou ktorého používateľ k portálu pristúpi. Návštevou a ďalším používaním portálu Slovakiana používateľ môže súhlasiť s tým, aby boli v jeho internetovom prehliadači uložené: a zároveň s tým, aby ich prevádzkovateľ využil na vlastné reklamné alebo štatistické účely. Súhlas používateľa trvá po dobu zachovania nastavení jeho internetového prehliadača. Zmenou nastavení internetového prehliadača používateľa alebo zákazom používania súborov cookies súhlas používateľa zaniká. </li>
    <ul><li><b>dlhodobé cookies </b> - slúžiace predovšetkým k dlhodobému zachovaniu osobných nastavení internetového prehliadača používateľa. Dlhodobé cookies zostávajú uchované v internetovom prehliadači používateľa aj po vypnutí zariadenia, pomocou ktorého používateľ portál navštívil. Ak používateľ s dlhodobým cookies súhlasí, má možnosť využívať portál Slovakiana v širšom rozsahu, napríklad ukladať si svoje obľúbené články a kultúrne objekty do zložky „Obľúbené“. Tie budú dostupné len na zariadení, v ktorom túto funkcionalitu využil a len do času, kým používateľ nevymaže pamäť daného zariadenia.</li>
    </ul></li>
    </ol>`,
    text13: `Čl. VII<br/>
    Záverečné ustanovenia`,
    text14: `<ol><li>Komunikácia medzi prevádzkovateľom a používateľom prebieha v  elektronickej alebo písomnej forme. Oznámenia používateľa a prevádzkovateľa sa považujú za doručené okamihom obdržania potvrdenia o doručení e-mailu alebo listu druhej strane. </li>
    <li>Tieto VPP sú platné a účinné dňom ich zverejnenia.</li>
    <li>VPP sú dohodnuté na neurčitý čas. Ich záväznosť vo vzťahu medzi prevádzkovateľom a používateľom nadobúda účinnosť od okamihu prvého prístupu používateľa na portál Slovakiana. Podmienky používania sa riadia právom Slovenskej republiky a náležitosti, ktoré implicitne neriešia, sa riadia príslušnými právnymi predpismi, predovšetkým Občianskym zákonníkom, Trestným zákonom, autorským zákonom, zákonom o reklame, zákonom o ochranných známkach a pod.</li>
    <li>Prevádzkovateľ je oprávnený VPP zmeniť. Nové znenie VPP je prevádzkovateľ povinný zverejniť na portáli Slovakiana vopred. Nové znenie VPP nadobúda účinnosť dňom ich zverejnenia na portáli Slovakiana.</li>
    <li>Znenie týchto VPP bolo zverejnené dňa 6. júna 2023</li>
    </ol>`,

    text21: `Riadne spracúvanie Vašich osobných údajov je pre nás dôležité a ich ochrana je úplnou samozrejmosťou. Pri spracúvaní osobných údajov môžete uplatniť tieto práva:
    <br/>
    <ol type="a">
    <li><b>Informácie o spracúvaní Vašich osobných údajov</b><br/>
    Obsahom informácie sú najmä totožnosť a kontaktné údaje prevádzkovateľa, jeho zástupcu a prípadne aj zodpovednej osoby za ochranu osobných údajov, účely spracúvania, kategórie dotknutých osobných údajov, príjemca alebo kategórie príjemcov osobných údajov, informácie o prenose osobných údajov do tretích krajín, doba uchovávania osobných údajov, oprávnení prevádzkovatelia, výpočet Vašich práv, možnosť obrátiť sa na Úrad na ochranu osobných údajov, zdroj spracúvaných osobných údajov, informácie, či a ako dochádza k automatizovanému rozhodovaniu a profilovaniu.</li>
    <li><b>Právo na prístup k osobným údajom</b><br/>
    Máte právo na potvrdenie, či osobné údaje sú alebo nie sú spracúvané a ak áno, máte právo na prístup k informáciám o účeloch spracúvania, kategóriách dotknutých osobných údajov, príjemcoch alebo kategóriách príjemcov, dobu uchovávania osobných údajov, informácie o Vašich právach, o práve podať sťažnosť Úradu pre ochranu osobných údajov, informácie o zdroji osobných údajov, informácie o tom, či dochádza k automatizovanému rozhodovaniu a profilovaniu, informácie a záruky v prípade prenosu osobných údajov do tretej krajiny alebo medzinárodnej organizácie. Máte právo na poskytnutie kópií spracúvaných osobných údajov.</li>
    <li><b>Právo na opravu</b><br/>
    Spracúvame Vaše neaktuálne, či nepresné osobné údaje? Zmenili ste emailovú adresu? Informujte nás prosím, a my osobné údaje opravíme.
    <li><b>Právo na výmaz (právo byť zabudnutý) </b> V niektorých zákonom stanovených prípadoch sme povinní Vaše osobné údaje na Váš pokyn vymazať. Každá takáto žiadosť však podlieha individuálnemu vyhodnoteniu, či sú splnené podmienky, pretože napríklad Národné osvetové centrum, Nám. SNP č. 12, 812 34 Bratislava môže mať povinnosť, či oprávnený záujem, ak prevažuje nad Vašimi záujmami, si osobné údaje ponechať.</li>
    <li><b>Právo na obmedzenie spracúvania</b><br/>
    Ak si prajete, aby sme poskytli Vaše osobné údaje inému prevádzkovateľovi, inej spoločnosti, odovzdáme Vaše osobné údaje v zodpovedajúcom formáte, ak nám v tom nebudú brániť žiadne zákonné ani iné významné prekážky, Vami určenému subjektu.</li>
    <li><b>Právo na prenosnosť údajov</b><br/>
    Ak si prajete, aby sme poskytli Vaše osobné údaje inému prevádzkovateľovi, inej spoločnosti, odovzdáme Vaše osobné údaje v zodpovedajúcom formáte, ak nám v tom nebudú brániť žiadne zákonné ani iné významné prekážky, Vami určenému subjektu.</li>
    <li><b>Právo namietať a automatizované individuálne rozhodovanie</b><br/>
    Ak by ste zistili alebo sa len domnievate, že spracúvanie osobných údajov vykonávame v rozpore s ochranou Vášho súkromného a osobného života alebo v rozpore s právnymi predpismi, obráťte sa prosím na nás a požiadajte nás o vysvetlenie, či odstránenie vzniknutého nevyhovujúceho stavu. Námietku môžete ďalej vzniesť aj priamo proti automatizovanému rozhodovaniu, ak k takémuto rozhodovaniu prichádza.</li>
    <li><b>Právo podať podnet alebo sťažnosť na Úrad na ochranu osobných údajov</b><br/>
    Môžete sa kedykoľvek obrátiť s Vašim podnetom, či sťažnosťou vo veci spracúvania osobných údajov na dozorný orgán, a to na Úrad na ochranu osobných údajov Slovenskej republiky, so sídlom Hraničná 12, 820 07 Bratislava 27, Slovenská republika, IČO: 36 064 220, tel. č.: <a href='tel:+421/2/3231 3220'>+421/2/3231 3220</a>, webové stránky <a href='https://dataprotection.gov.sk/uoou/'>https://dataprotection.gov.sk/uoou/</a></li>
    </ol>`,
    text22: `Jednotlivé práva môžete uplatniť u prevádzkovateľa Národné osvetové centrum, Nám. SNP č. 12, 812 34 Bratislava a to e-mailom na adresu <a href='mailto:slovakiana@nocka.sk'>slovakiana@nocka.sk</a> alebo písomne do sídla, či na korešpondenčnú adresu prevádzkovateľa Národné osvetové centrum, Nám. SNP č. 12, 812 34 Bratislava.`,
    text23: `Všetky oznámenia a vyjadrenia k Vami uplatneným právam poskytujeme bezplatne. Ak by však bola žiadosť zjavne nedôvodná alebo neprimeraná, najmä preto, že by sa opakovala, sme oprávnení si účtovať primeraný poplatok zohľadňujúci administratívne náklady spojené s poskytnutím požadovaných informácií. V prípade opakovaného uplatnenia žiadosti o poskytnutie kópií spracúvaných osobných údajov si vyhradzujeme právo z tohto dôvodu účtovať primeraný poplatok za administratívne náklady.`,
    text24: `Vyjadrenie a prípadne informácie o prijatých opatreniach Vám poskytneme čo najskôr, najneskôr však do jedného mesiaca. Lehotu sme oprávnení v prípade potreby a vzhľadom na zložitosť a počet žiadostí predĺžiť o dva mesiace. O predĺžení lehoty vrátane uvedenia dôvodu Vás budeme informovať.`,
  },
  healthcheck: {
    title: 'Servery neodpovedajú!',
    message: 'Prepáčte, nemôžeme vám zobraziť túto stránku, pretože servery neodpovedajú.',
  },
  category: {
    category1: {
      title: 'Výtvarné umenie',
      pod1: 'Maľba',
      pod2: 'Kresba',
      pod3: 'Grafika',
      pod4: 'Fotografia',
      pod5: 'Socha, plastika a reliéf',
      pod6: 'Pohľadnica',
      pod7: 'Úžitkové umenie a remeslo',
    },
    category2: {
      title: 'Textové dokumenty',
      pod1: 'Kniha',
      pod2: 'Periodikum',
      pod3: 'Článok',
      pod4: 'Stará tlač',
      pod5: 'Archívny dokument',
      pod6: 'Tlačovina',
      pod7: 'Hudobnina',
      pod8: 'Kartografický dokument',
    },
    category3: {
      title: 'Historická zbierka',
      pod1: 'Platidlá a vecniny',
      pod2: 'Náradie, nástroje a výstroj',
      pod3: 'Zariadenie, vybavenie a doplnky',
      pod4: 'Zbrane, zbroje, strelivo',
      pod5: 'Hudobné nástroje a súčasti',
      pod6: 'Odev a odevné súčasti',
      pod7: 'Stroje s prístroje',
      pod8: 'Dopravné prostriedky',
    },
    category4: {
      title: 'Prírodné dedičstvo',
      pod1: 'Botanika',
      pod2: 'Zoológia',
      pod3: 'Geológia',
      pod4: 'Paleontológia',
    },
    category5: {
      title: 'Architektúra a pamiatky',
      pod1: 'Architektonické pamiatky',
      pod2: 'Technické pamiatky',
      pod3: 'Historické pamiatky',
      pod4: 'Výtvarné pamiatky',
      pod5: 'Pamiatky ľudového staviteľstva',
      pod6: 'Historická zeleň',
      pod7: 'Archeológia ',
      pod8: 'Pamiatkové zóny a rezervácie',
      pod9: 'Architektonické návrhy',
    },
    category6: {
      title: 'Audiovizuálny obsah ',
      pod1: 'Filmové ukážky',
      pod2: 'Hudobné ukážky',
      pod3: 'Návody',
      pod4: 'Vystúpenia',
      pod5: 'Populárno-náučné videá ',
    },
    category7: {
      title: 'Tradičná ľudová kultúra ',
    },
  },
  categoryVideo: {
    category1: {
      title: '',
      pod1: '',
      pod2: '',
      pod3: '',
      pod4: '',
      pod5: '',
      pod6: '',
    },
    category2: {
      title: '',
      pod1: '',
      pod2: '',
      pod3: '',
    },
    category3: {
      title: '',
    },
  },
  subscribe: {
    validEmail: 'Email musí byť v správnom tvare!',
    subcribeSuccess: `Úspešne ste sa prihlásili na odber noviniek`,
    subscribeAlready: `Už ste prihlásený na odber noviniek`,
  },
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level_1: `menu level 1`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  story: {
    category1: `kategória 1`,
    category2: `kategória 2`,
    category3: `kategória 3`,
  },
  cookie: {
    title: 'Táto webová stránka používa súbory cookie',
    text: 'Tento web používa súbory cookie na poskytovanie služieb, prispôsobenie reklám a analýzu prenosov. Informácie o tom, ako používate tento web, sa zdieľajú s Googlom. Používaním tohto webu vyjadrujete svoj súhlas s používaním súborov cookie.',
    link: 'Viac informácií',
    allow: 'Povoliť',
    necesary: 'Nevyhnutné',
    marketing: 'Marketing',
    statistic: 'Štatistické',
    options: 'Zobraziť predvoľby',
    accept_options: 'Uložiť preferencie',
    deny: 'Odmietnuť',
    setting: 'Aktuálne máte cookies',
    on: 'zapnuté',
    off: 'vypnuté',
  },
  notFoundPage: {
    title: 'Ľutujeme, stránka sa nenašla!',
    desc: 'Ľutujeme, nepodarilo sa nám nájsť stránku, ktorú hľadáte. Možno ste zle zadali adresu URL? Nezabudnite si skontrolovať pravopis.',
    button: 'Domovská stránka',
  },
  urls: {
    co: '/kulturne-objekty',
    coId: '/kulturne-objekty/:id',
    coIdSimilar: '/kulturne-objekty/:id/:originSimilarName',
    articles: '/clanky',
    articlesId: '/clanky/:id',
    articleIdName: '/clanky/:id/:name',
    home: '/domov',
    video: '/videoteka',
    videoId: '/videoteka/:id',
    videoIdSimilar: '/videoteka/:id/:originSimilarName',
    map: '/tipy-na-vylet',
    mapId: '/tipy-na-vylet/:id',
    mapIdName: '/tipy-na-vylet/:id/:name',
    sheets: '/scitacie-harky',
    sheetsId: '/scitacie-harky/:id',
    slovakiana: '/o-slovakiane',
    edu: '/edu-materialy',
    eduId: '/edu-materialy/:id',
    favorites: '/oblubene',
    institutions: '/zoznam-institucii',
    institutionsId: '/zoznam-institucii/:id',
    oldMap: '/typy-na-vylet',
    oldMapId: '/typy-na-vylet/:id',
    oldMapIdName: '/typy-na-vylet/:id/:name',
    accesibility: '/prehlasenie-o-pristupnosti',
    conditions: '/vseobecne-podmienky-pouzivania',
    authorRights: '/autorske-prava',
    other: '*',
  },
};
export default sk;
